export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/main/pages/index.vue'),
    /* no children */
    meta: {
      "auth": false,
      "layout": "frontend",
      "namespace": "app"
    }
  },
  {
    path: '/admin',
    /* internal name: '/admin' */
    /* no component */
    children: [
      {
        path: '',
        name: '/admin/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/main/pages/admin/index.vue'),
        /* no children */
        meta: {
          "namespace": "admin"
        }
      },
      {
        path: 'feeds',
        /* internal name: '/admin/feeds' */
        /* no component */
        children: [
          {
            path: '',
            name: '/admin/feeds/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/main/pages/admin/feeds/index.vue'),
            /* no children */
            meta: {
              "namespace": "admin"
            }
          },
          {
            path: ':id',
            name: '/admin/feeds/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/main/pages/admin/feeds/[id].vue'),
            /* no children */
            meta: {
              "namespace": "admin"
            }
          }
        ],
        meta: {
          "namespace": "admin"
        }
      },
      {
        path: 'quarry_tasks',
        /* internal name: '/admin/quarry_tasks' */
        /* no component */
        children: [
          {
            path: '',
            name: '/admin/quarry_tasks/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/main/pages/admin/quarry_tasks/index.vue'),
            /* no children */
            meta: {
              "namespace": "admin"
            }
          }
        ],
        meta: {
          "namespace": "admin"
        }
      }
    ],
    meta: {
      "namespace": "admin"
    }
  },
  {
    path: '/channels',
    /* internal name: '/channels' */
    /* no component */
    children: [
      {
        path: '',
        name: '/channels/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/main/pages/channels/index.vue'),
        /* no children */
        meta: {
          "layout": "app",
          "namespace": "app"
        }
      },
      {
        path: ':id',
        name: '/channels/[id]',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/main/pages/channels/[id].vue'),
        /* no children */
        meta: {
          "layout": "app",
          "namespace": "app"
        }
      },
      {
        path: 'new',
        name: '/channels/new',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/main/pages/channels/new.vue'),
        /* no children */
        meta: {
          "layout": "app",
          "namespace": "app"
        }
      }
    ],
    meta: {
      "namespace": "app"
    }
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/main/pages/dashboard.vue'),
    /* no children */
    meta: {
      "layout": "app",
      "namespace": "app"
    }
  },
  {
    path: '/sign_in',
    name: 'sign_in',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/cappsutra/rsszip/app/javascript/main/pages/sign_in.vue'),
    /* no children */
    meta: {
      "auth": false,
      "layout": "frontend",
      "namespace": "app"
    }
  }
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}

