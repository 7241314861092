import { defineStore, getActivePinia } from 'pinia'
import { ref, type App } from 'vue'
import { KlassCache } from './KlassCache'
import type { BaseRequest } from '$$/requests'

export const usePageStore = defineStore('Page', () => {
  const cache = new KlassCache<BaseRequest<any>>()
  const id = ref(0)

  function newPage() {
    id.value += 1
  }

  function cleanup() {
    cache.clear()
  }

  return { id, newPage, cleanup, cache }
})
